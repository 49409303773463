<template>
  <div class="chatBoxComponent bg-white">
    <!-- Loader -->
    <div class="loadingBox" v-if="loading">
      <div v-loading="true" class="w-full h-14 mt-6"></div>
    </div>

    <!--  No conversation Selected -->
    <div class="noConversationSelected" v-else-if="!conversationId">
      <div class="w-full text-center text-gray-400">
        <i class="fa-light fa-messages text-6xl mb-3"></i>
        <div>Select conversation to view messages.</div>
      </div>
    </div>

    <div class="flex" v-else style="height: 100vh">
      <div class="chatBox flex-1 border-r">
        <!-- Left side message composer -->
        <div class="coversation-header flex">
          <div class="flex-1 text-xl font-normal">
            <div>{{ customer.name ? customer.name : 'Name Not Available' }}</div>

            <!-- Channel Meta -->
            <div class="conversation-channel-meta" v-if="channel">
              <div v-if="channel.type == 'instagram'" class="text-xs text-gray-500">
                <i class="fa-brands fa-instagram text-pink-600 text-bold"></i>
                <span class="instagram_color font-normal"> {{ channel.meta.instagramUsername }} </span>
              </div>
              <div v-else-if="channel.type == 'whatsApp'" class="text-xs">
                <i class="fa-brands fa-whatsapp text-green-600 text-bold text-xs"></i>
                <span class="text-green-600 font-normal"> {{ channel.meta.name }} </span>

                <!-- <span v-if="conversation.channel_meta && conversation.channel_meta.number">{{ conversation.channel_meta.number }}</span>
                <span v-else>Unknown Number</span> -->
              </div>
              <div v-else-if="channel.type == 'facebook_messenger'" class="text-xs">
                <i class="fa-brands fa-facebook-messenger text-blue-500 text-blue-500 text-bold"></i>
                <span class="text-blue-500 font-normal"> {{ channel.meta.pageName }} </span>
              </div>
            </div>
          </div>

          <!-- Assign To Agent -->
          <div class="flex-none mr-2">
            <el-select class="w-full" size="small" placeholder="Assign To Agent" v-model="conversation.agent_id" filterable @change="onAssignChatToAgent">
              <el-option v-for="(agent, index) in agentList" :key="index" :label="agent.first_name + ' ' + agent.last_name" :value="agent.id"> </el-option>
            </el-select>
          </div>

          <!-- Open Close Chat -->
          <div class="flex-none">
            <el-button size="small" type="primary" v-if="conversation.status == 'open'" @click="onCloseConversation">Close Chat</el-button>
            <el-button size="small" type="primary" v-else @click="onReOpenConversation">Re-open Chat</el-button>
          </div>
        </div>

        <!-- Message List -->
        <div id="messageListContainer" class="message-list" ref="messageListContainer">
          <div class="w-full flex mt-4" v-for="(message, index) in messageList" :key="index">
            <!-- Customer Name -->
            <div class="flex-none pr-1" v-if="message.sender_type == 'customer'">
              <el-tooltip class="item" effect="dark" :content="customer.name ? customer.name : 'Unknown'" placement="bottom-start">
                <div class="messageSenderInfo">{{ customer.name ? customer.name[0].toUpperCase() : 'Unknown' }}</div>
              </el-tooltip>
            </div>

            <!-- Message Content -->
            <div class="flex-1 message-item-container" :class="{ 'from-agent': message.sender_type != 'customer' }">
              <WhatsAppMessageBlock v-if="channel.type == 'whatsApp'" :customer="customer" :channel="channel" :message="message" @showMediaReviewPopup="showMediaPreviewPopup"></WhatsAppMessageBlock>
              <InstagramMessageBlock v-else-if="channel.type == 'instagram'" :customer="customer" :channel="channel" :message="message" @showMediaReviewPopup="showMediaPreviewPopup"></InstagramMessageBlock>
              <FacebookMessageBlock v-else-if="channel.type == 'facebook_messenger'" :customer="customer" :channel="channel" :message="message" @showMediaReviewPopup="showMediaPreviewPopup"></FacebookMessageBlock>

              <div class="message-status-info text-gray-400">
                {{ message.created_at_text }}

                <template v-if="message.sender_type != 'customer'">
                  <img v-if="message.status == 'sent'" class="h-5" src="@/assets/images/chatBox/whatsApp-message-status/sent.png" />
                  <img v-if="message.status == 'delivered'" class="h-5" src="@/assets/images/chatBox/whatsApp-message-status/delivered.png" />
                  <img v-if="message.status == 'read'" class="h-5" src="@/assets/images/chatBox/whatsApp-message-status/read.png" />
                  <img v-if="message.status == 'failed'" class="h-4" src="@/assets/images/chatBox/whatsApp-message-status/failed.png" />
                </template>
              </div>
            </div>

            <!-- Agent Name -->
            <div class="flex-none pl-1" v-if="message.sender_type != 'customer'">
              <!-- Sender Type - Agent -->
              <el-tooltip v-if="message.sender_type == 'agent' && message.agent_info" class="item" effect="dark" :content="message.agent_info.first_name + ' ' + message.agent_info.last_name" placement="bottom-start">
                <div class="messageSenderInfo agentSideSenderInfo">{{ message.agent_info.first_name[0].toUpperCase() + message.agent_info.last_name[0].toUpperCase() }}</div>
              </el-tooltip>
              <el-tooltip v-else-if="message.sender_type == 'campaign'" class="item" effect="dark" content="Campaign Message" placement="bottom-start">
                <div class="messageSenderInfo agentSideSenderInfo isCampaignMessage"><i class="fa-light fa-bullhorn"></i></div>
              </el-tooltip>
              <el-tooltip v-else-if="message.sender_type == 'bot'" class="item" effect="dark" content="Bot" placement="bottom-start">
                <div class="messageSenderInfo agentSideSenderInfo isCampaignMessage"><i class="fa-light fa-robot"></i></div>
              </el-tooltip>
            </div>
          </div>
        </div>

        <!-- Message Composer -->
        <div class="message-composer">
          <!-- Session Expiry Info -->
          <!-- <div v-if="!sessionExpired" class="py-1 mb-2 rounded-xl text-xs bg-yellow-100 text-yellow-700 text-center">WhatsApp Session will expire at {{ sessionTimeLeftDisplayText }}</div> -->

          <MessageComposerComponent :channelInfo="channel" :conversationId="conversation.id" :customer="customer" :sessionExpired="sessionExpired" @onNewMesageComposed="onNewMesageComposed"></MessageComposerComponent>
        </div>
      </div>

      <!-- Right Side -->
      <div class="bg-white h-full w-80">
        <ConversationMetaComponent :incomingTicketTags="conversation.tags" :ticketId="conversationId" @onShowPastTicket="onShowPastTicket" :customer="customer"></ConversationMetaComponent>
      </div>
    </div>

    <MediaPreviewPopup ref="mediaPreviewPopup"></MediaPreviewPopup>
  </div>
</template>

<style lang="scss" src="./chatBox.scss"></style>

<script>
import chatBoxComponent from './chatBoxComponent';
export default chatBoxComponent;
</script>
