<template>
  <div class="chatMainLeftSide bg-white xl:w-80 xl:flex-shrink-0 border-r">
    <div class="chatLeftMainContainer">
      <!-- Header -->
      <div class="conversation-header border-b bg-gray-50">
        <!-- Start : Search Functional  -->
        <div class="flex items-center justify-between px-3 py-2">
          <div class="text-2xl font-semibold">Inbox</div>
          <el-button size="small" type="primary" @click="onSearch">Search</el-button>
        </div>

        <!-- Search Dialog Box Open -->
        <el-dialog title="Chat Search By Conversation Or Name Or Contact" :visible.sync="isSearchActive" width="50%">
          <div class="flex items-center">
            <el-input v-model="filterText" style="width: 100%" placeholder="Type something" prefix-icon="el-icon-search" @click="performSearch"></el-input>
          </div>
          <div class="flex items-center mt-2">
            <!-- Radio Button Group -->
            <el-radio-group v-model="radio" @change="onSearchTypeChange" style="margin-left: 1%">
              <el-radio :label="'all'">All</el-radio>
              <el-radio :label="'whatsApp'">WhatsApp</el-radio>
              <el-radio :label="'instagram'">Instagram</el-radio>
            </el-radio-group>
          </div>
          <div class="flex items-center"></div>
          <!-- Search Filter Result List -->
          <div v-if="searchFilterList.length > 0">
            <div class="conversation-list" style="max-height: 400px; overflow-y: auto">
              <div class="conversation-item px-3 py-3 cursor-pointer border-b relative" v-for="(conversation, index) in searchFilterList" :key="index" @click="onSearchConversationSelect(index)">
                <div class="flex">
                  <div class="flex-1">
                    <span v-if="conversation.customer_name && conversation.customer_name" class="text-base text-black"
                      >{{ conversation.customer_name }}
                      <small v-if="conversation.status == 'open'" class="text-xs text-green-500 inline-block whitespace-nowrap rounded-md border-2 border-green-100 px-1 py-0 text-center align-top">open</small>
                      <small v-else class="text-xs text-red-500 inline-block whitespace-nowrap rounded-md border-2 border-red-100 px-1 py-0 text-center align-top">closed</small>
                    </span>
                    <span v-else class="text-sm">Name Not Available</span>
                  </div>
                  <div class="" style="font-size: 18px">
                    <el-tooltip class="item" effect="dark" :content="conversation.channel?.text ?? ''" placement="right">
                      <i v-if="conversation.channel_type == 'whatsApp'" class="fa-brands fa-whatsapp text-green-600"></i>
                      <i v-if="conversation.channel_type == 'instagram'" class="fa-brands fa-instagram text-pink-600"></i>
                      <i v-if="conversation.channel_type == 'facebook_messenger'" class="fa-brands fa-facebook-messenger text-blue-500"></i>
                    </el-tooltip>
                  </div>
                </div>
                <div class="text-gray-500 leading-5 text-xs pb-1 lastMessage flex justify-between">
                  <span>{{ conversation.last_message_desc ? conversation.last_message_desc : null }}</span>
                  <span>{{ conversation.created_at_date ? conversation.created_at_date : null }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <span class="text-sm">No Record Found</span>
          </div>
        </el-dialog>
        <!-- End : Search Functional  -->
        <!-- Filters -->
        <ConversationListFilter ref="conversionFilter" @resetAndFetch="resetAndFetch"></ConversationListFilter>
      </div>

      <!-- Loader -->
      <div v-if="fetchingConversations && this.conversationList.length == 0" style="margin-top: 30vh">
        <div v-loading="true" class="w-full h-14"></div>
      </div>

      <template v-else>
        <!-- Agent Allocation Tabs -->
        <div class="flex allocationTab">
          <div @click="onAssignedStatusFilterChange('all')" :class="{ selectedStatus: assignedStatus == 'all' }" class="flex-none w-20 allocationTabItem">All</div>
          <div @click="onAssignedStatusFilterChange('you')" :class="{ selectedStatus: assignedStatus == 'you' }" class="flex-none w-24 allocationTabItem">You</div>
          <div @click="onAssignedStatusFilterChange('unassigned')" :class="{ selectedStatus: assignedStatus == 'unassigned' }" class="flex-1 allocationTabItem">UnAssigned</div>
          <!-- <div @click="onAssignedStatusFilterChange('spam')" :class="{ selectedStatus: assignedStatus == 'spam' }" class="w-16 allocationTabItem">Spam</div> -->
        </div>

        <!-- Conversation List -->
        <div v-if="conversationList.length > 0" class="conversation-list">
          <div class="conversation-item px-3 py-3 cursor-pointer border-b relative" @click="onConversationSelect(index)" :class="{ selected: selectedConversation && conversation.id == selectedConversation.id }" v-for="(conversation, index) in conversationList" :key="index">
            <!-- Row -1  -->
            <div class="flex">
              <!-- Unacknowledged Dot -->
              <div class="-mt-3 pr-1" v-if="!conversation.agent_acknowledged">
                <i class="fa-solid fa-period text-secondary-600" style="font-size: 25px"></i>
              </div>

              <!-- Customer Name -->
              <div class="flex-1">
                <span v-if="conversation.customer && conversation.customer.name" class="text-sm text-black" :class="{ 'font-semibold': !conversation.agent_acknowledged }">{{ conversation.customer.name }}</span>
                <span v-else class="text-sm">Name Not Available</span>
              </div>

              <!-- Messaging Channel Icon -->
              <div class="" style="font-size: 18px">
                <el-tooltip class="item" effect="dark" :content="conversation.channel?.text ?? ''" placement="right">
                  <i v-if="conversation.channel_type == 'whatsApp'" class="fa-brands fa-whatsapp text-green-600"></i>
                  <i v-if="conversation.channel_type == 'instagram'" class="fa-brands fa-instagram text-pink-600"></i>
                  <i v-if="conversation.channel_type == 'facebook_messenger'" class="fa-brands fa-facebook-messenger text-blue-500"></i>
                </el-tooltip>
              </div>
            </div>

            <!-- Row 2 - Description -->
            <div class="text-gray-500 leading-5 text-xs pb-1 lastMessage">
              {{ conversation.last_message_desc ? conversation.last_message_desc.substring(0, 40) : '' }}
            </div>

            <!-- Row 3 - Meta -->
            <div class="flex">
              <!-- Agent Name -->
              <div class="flex-1">
                <template :style="{ background: conversation.agent_info_color }">
                  <el-tooltip class="item" effect="dark" :content="conversation.agent_info ? conversation.agent_info.first_name + ' ' + conversation.agent_info.last_name : 'Un-Assigned'" placement="bottom-start">
                    <div v-if="!conversation.agent_id" class="agentNameCircle agentUnassignedCircle">U</div>
                    <div class="agentNameCircle" v-else>
                      {{ conversation.agent_info.first_name[0].toUpperCase() + conversation.agent_info.last_name[0].toUpperCase() }}
                    </div>
                  </el-tooltip>
                </template>
              </div>

              <!-- Conversation Created At -->
              <div class="text-xs text-gray-500 pt-1">{{ conversation.created_at_display }}</div>
            </div>
          </div>

          <!-- Load more conversations button -->
          <div class="p-3" v-if="showLoadMoreConversationsButton">
            <el-button @click="onLoadMoreConversations" class="w-full" type="primary" size="small" plain>Load More Conversations</el-button>
          </div>
          <div v-if="fetchingConversations">
            <div v-loading="true" class="w-full h-14"></div>
          </div>
        </div>

        <!-- No Conversations -->
        <div v-else class="bg-gray-0" style="padding-top: 40vh">
          <div class="w-full text-center text-gray-400">
            <i class="fa-light fa-messages text-6xl mb-3"></i>
            <div>No Conversations</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" src="./conversationList.scss"></style>

<script>
import conversationListComponent from './conversationListComponent';
export default conversationListComponent;
</script>
