import conversationService from '@/services/conversationService';
import searchService from '@/services/searchService';
import ConversationListFilter from './conversationListFilters.vue';
import moment from 'moment';
import * as _ from 'lodash';

export default {
  name: 'campaignList',
  components: {
    ConversationListFilter
  },
  data() {
    return {
      fetchingConversations: false,
      selectedConversation: null,
      assignedStatus: 'all',
      conversationStatus: 'open',
      order: 'newest',
      searchOptions: {
        term: ''
      },

      // Pagination.
      pageOptions: {
        pageSize: 20,
        pageNumber: 0
      },
      showLoadMoreConversationsButton: true,

      conversationList: [],

      // Search Functionality.
      isSearchActive: false,
      filterText: '',
      searchFilterList: [],
      radio: 'all' // Default value for radio button
    };
  },

  // Watch filterText for search
  watch: {
    filterText() {
      if (this.isSearchActive) {
        this.performSearch();
      }
    }
  },

  methods: {
    async resetAndFetch() {
      this.pageOptions.pageNumber = 0;
      this.conversationList = [];
      this.fetchList();
    },

    async fetchList(fetchCount) {
      try {
        this.fetchingConversations = true;

        let filters = this.$refs.conversionFilter.getFilters();

        let params = {
          filters: filters,
          pageOptions: this.pageOptions,
          assignType: this.assignedStatus,
          getCount: fetchCount
        };

        // Fetch List
        let response = await conversationService.getList(params);

        // Put formatted display time
        for (let i = 0; i < response.data.list.length; i++) {
          response.data.list[i] = this.formatConversationForDisplay(response.data.list[i]);
        }

        // Set count if requested.
        if (fetchCount) {
          this.total = response.data.count;
        }

        // Merge with existing conversation.
        this.conversationList = this.conversationList.concat(response.data.list);

        // Update pagination info.
        this.showLoadMoreConversationsButton = true;
        if (response.data.list.length < this.pageOptions.pageSize) {
          this.showLoadMoreConversationsButton = false;
        }

        this.fetchingConversations = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Something went wrong. Please contact support.');
      }
    },

    onLoadMoreConversations() {
      this.pageOptions.pageNumber++;
      this.fetchList(false);
    },

    async onAssignedStatusFilterChange(newAssignedStatus) {
      this.assignedStatus = newAssignedStatus;
      this.resetAndFetch();
    },

    formatConversationForDisplay(conversation) {
      let currentTime = moment();
      let messageTime = moment(parseInt(conversation.last_message_at));
      let minutes = moment.duration(currentTime.diff(messageTime)).asMinutes();
      let hours = moment.duration(currentTime.diff(messageTime)).asHours();
      let days = moment.duration(currentTime.diff(messageTime)).asDays();

      let displayText = '';
      if (minutes < 2) {
        displayText = 'Now';
      } else if (minutes < 60) {
        displayText = parseInt(minutes) + 'min';
      } else if (hours < 24) {
        displayText = parseInt(hours) + 'h';
      } else if (days < 7) {
        displayText = parseInt(days) + 'd';
      } else {
        displayText = messageTime.format('DD MMM');
      }
      conversation.created_at_display = displayText;

      return conversation;
    },

    onConversationSelect(index) {
      this.selectedConversation = this.conversationList[index];
      this.$emit('onConversationSelect', this.selectedConversation.id);
      this.isSearchActive = false;
    },

    onSocketPayload(payload) {
      console.log('Socket: ConvList: New Message:', payload);

      if (payload.action == 'new') {
        // Check if conv already exists, if so, put it to top.
        let conversationIndex = _.findIndex(this.conversationList, (conv) => {
          return conv.id == payload.payload.id;
        });
        if (conversationIndex >= 0) {
          this.conversationList[conversationIndex] = payload.payload;
        } else {
          // If new conversation. Add At Top.
          payload.payload = this.formatConversationForDisplay(payload.payload);
          this.conversationList.unshift(payload.payload);
        }
      } else if (payload.action == 'new-message') {
        // Mark as unread.
        let conversationIndex = _.findIndex(this.conversationList, (conv) => {
          return conv.id == payload.payload.id;
        });
        if (conversationIndex >= 0) {
          let conversation = this.conversationList[conversationIndex];

          conversation.last_message_desc = payload.payload.last_message_desc;
          conversation.last_message_at = payload.payload.last_message_at;
          conversation = this.formatConversationForDisplay(conversation);

          // Check to mark as un-read.
          if (payload.senderType == 'customer') {
            let selectedId = this.selectedConversation ? this.selectedConversation.id : null;
            if (!selectedId || payload.payload.id != selectedId) {
              conversation.agent_acknowledged = false;
            }
          }

          // Put conversation at top, if not already at top.
          if (conversationIndex != 0) {
            this.conversationList.splice(conversationIndex, 1);
            this.conversationList.unshift(conversation);
          }
        }
      } else if (payload.action == 'agent-change') {
        // Agent Assigned.
        let conversation = _.find(this.conversationList, (conv) => {
          return conv.id == payload.payload.id;
        });

        if (conversation) {
          conversation.agent_info = payload.payload.agent_info;
          conversation.agent_id = payload.payload.agent_id;
        }
      } else if (payload.action == 'acknowledge') {
        // Acknowledge given conversation.
        // If new message in conversation, mark as unread if not selected.
        let conversation = _.find(this.conversationList, (conv) => {
          return conv.id == payload.payload.id;
        });
        if (conversation) {
          conversation.agent_acknowledged = true;
        }
      } else if (payload.action == 'status-change') {
        let conversationId = payload.payload.id;
        let newStatus = payload.payload.status;

        // Add To List If eligible.
        if (this.conversationStatus == newStatus) {
          // IF conversation not exists, add it.
          let conversationIndex = _.findIndex(this.conversationList, (conv) => {
            return conv.id == conversationId;
          });
          if (conversationIndex < 0) {
            payload.payload = this.formatConversationForDisplay(payload.payload);
            // Find Index To Add.
            let lastMsgAt = parseInt(payload.payload.last_message_at);
            let indexToAdd = null;
            for (let i = 0; i < this.conversationList.length; i++) {
              let compareTo = parseInt(this.conversationList[i].last_message_at);
              console.log('Cpmpare', compareTo, lastMsgAt);
              if (compareTo < lastMsgAt) {
                console.log('matched', compareTo, lastMsgAt);
                indexToAdd = i;
                break;
              }
            }

            if (indexToAdd == null) indexToAdd = this.conversationList.length;
            console.log('Index To Add:', indexToAdd);
            this.conversationList.splice(indexToAdd, 0, payload.payload);
          }
        }

        // Remove From List If eligible.
        let removeConvFromList = false;
        if (this.conversationStatus == 'closed' && newStatus == 'open') removeConvFromList = true;
        if (this.conversationStatus == 'open' && newStatus == 'closed') removeConvFromList = true;
        if (removeConvFromList) {
          let conversationIndex = _.findIndex(this.conversationList, (conv) => {
            return conv.id == conversationId;
          });
          if (conversationIndex >= 0) {
            this.conversationList.splice(conversationIndex, 1);

            // Show Next Conversation.
            let nextConvId = null;
            if (this.conversationList.length > conversationIndex) {
              nextConvId = this.conversationList[conversationIndex].id;
            }

            if (nextConvId) {
              this.onConversationSelect(conversationIndex);
            } else {
              this.$emit('onConversationRemoved', {
                current: conversationId,
                next: nextConvId
              });
            }
          }
        }
      }
    },

    // Start: Search Functionality. Method

    // Search Button Clicked Event
    async onSearch() {
      this.isSearchActive = true;
      this.filterText = '';
      this.searchFilterList = [];
      this.radio = 'all';
    },

    // Search Type Changed Event
    async onSearchTypeChange() {
      console.log('Search Type Changed:', this.radio);
      this.performSearch();
    },

    // Search Filter List
    async performSearch() {
      this.searchFilterList = [];
      if (this.filterText.trim() === '') {
        return;
      }
      this.search();
    },

    // search filter by conversation
    async search() {
      // clear searchFilterList First
      this.searchFilterList = [];

      // Find customer by name or email in the conversation list
      let params = {
        searchText: this.filterText,
        filterChannel: this.radio
      };
      let response = await searchService.searchChat(params);
      this.filterList = response.data;

      // Return the filtered list
      this.searchFilterList = this.filterList;
      return this.searchFilterList;
    },

    // Search Filter List Select Conversation Should be Opened
    onSearchConversationSelect(index) {
      if (this.filterList[index].conversation_id) {
        this.selectedConversation = this.filterList[index];
        this.$emit('onConversationSelect', this.selectedConversation.conversation_id);
        this.isSearchActive = false;
      } else {
        this.selectedConversation = this.filterList[index];
        this.$emit('onConversationSelect', this.selectedConversation.id);
        this.isSearchActive = false;
      }
    }

    // End: Search Functionality. Method
  },

  mounted() {
    // Modify Query-String
    const query = this.$route.query;
    this.searchOptions = query.search ? query.search : this.searchOptions;

    this.fetchList(true);
  }
};
