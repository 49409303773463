<template>
  <div>
    <el-row class="mt-2" :gutter="10">
      <el-col :span="12">
        <el-select size="mini" v-model="filters.status" class="w-full" @change="resetAndFetch()">
          <el-option key="open" label="Open (30)" value="open"> </el-option>
          <el-option key="closed" label="Closed" value="closed"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="12" class="text-right">
        <el-select size="mini" v-model="filters.orderBy" class="w-full" @change="resetAndFetch()">
          <el-option key="newest" label="Newest" value="newest"> </el-option>
          <el-option key="oldest" label="Oldest" value="oldest"> </el-option>
        </el-select>
      </el-col>

      <!-- Tags Filter -->
      <el-col :span="12" class="pt-2">
        <el-select class="w-full" v-model="filters.tag" placeholder="Filter By Tag" @change="resetAndFetch" size="mini" filterable clearable>
          <el-option v-for="(tag, tagIndex) in tagList" :key="tagIndex" :label="tag.name" :value="tag.id"> <i class="fa-solid fa-circle" :style="{ color: tag.color, 'font-size': '9px' }"></i> {{ tag.name }}</el-option>
        </el-select>
      </el-col>

      <!-- Inbox -->
      <el-col :span="12" class="pt-2">
        <el-select class="w-full" v-model="filters.inbox" placeholder="Filter By Inbox" @change="resetAndFetch" size="mini" filterable clearable>
          <el-option v-for="inbox in inboxList" :key="inbox.id" :label="inbox.provider_name" :value="inbox.id">
            <i v-if="inbox.channel_type == 'whatsApp'" class="fa-brands fa-whatsapp text-green-600"></i>
            <i v-if="inbox.channel_type == 'instagram'" class="fa-brands fa-instagram text-pink-600"></i>
            <i v-if="inbox.channel_type == 'facebook_messenger'" class="fa-brands fa-facebook-messenger text-blue-500"></i>

            <span class="pl-1">{{ inbox.provider_name }}</span>
          </el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import chatSettingsService from './../../../services/chatSettingsService';
import companySettingService from './../../../services/companySettingService';
export default {
  name: 'conversationListFilters',
  data() {
    return {
      filters: {
        status: 'open',
        orderBy: 'newest',
        tag: null,
        inbox: null
      },

      tagList: [],
      inboxList: []
    };
  },
  methods: {
    resetAndFetch() {
      this.$emit('resetAndFetch', {
        conversationStatus: this.conversationStatus,
        order: this.order,
        tag: this.tag
      });
    },

    getFilters() {
      return this.filters;
    },

    async fetchTagsList() {
      try {
        const response = await companySettingService.getTagsList();
        this.tagList = response.data ? JSON.parse(response.data) : [];
      } catch (err) {
        console.log('Failed to fetch tags list. Please contact support.', err);
      }
    },

    async fetchInboxChannels() {
      try {
        let response = await chatSettingsService.getInstalledChannels();
        this.inboxList = response.data;
        // console.log('Channels List', this.inboxList);
      } catch (err) {
        console.log('Failed to fetch details. Please contact support.', err);
      }
    }
  },

  mounted() {
    this.fetchInboxChannels();
    this.fetchTagsList();
  }
};
</script>

<style></style>
