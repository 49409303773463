<template>
  <div class="messageComposerComponent xl:w-80 xl:flex-shrink-0">
    <!-- Channel Archived -->
    <div v-if="channelInfo.archived" class="sessionExpiredComposerMsg rounded-md bg-yellow-50 py-3 px-3">
      <div class="text-sm text-yellow-700 font-semibold text-center">This Inbox channel has been archived.</div>
    </div>

    <!-- Session Expired -->
    <template v-else-if="sessionExpired && ['whatsApp', 'instagram'].indexOf(channelInfo.type) >= 0">
      <!-- WhatsApp Session Expired -->
      <div v-if="channelInfo.type == 'whatsApp'" class="sessionExpiredComposerMsg rounded-md bg-yellow-50 py-3 px-3 relative w-ful">
        <h3 class="text-base font-semibold text-yellow-800">Whatspp Session Has Expired.</h3>
        <div class="mt-1 text-sm text-yellow-700">
          <p>The customer has not spoken to you in the last 24 hours. You can only send pre-approved templates once whatsapp session expires after 24 hours.</p>
        </div>
        <div class="mt-2">
          <el-button type="primary" size="small" @click="showTemplateMessagePopup">Send WhatsApp Template Message</el-button>
        </div>
      </div>

      <!-- Instagram Session Expired -->
      <div v-else-if="channelInfo.type == 'instagram'" class="sessionExpiredComposerMsg rounded-md bg-yellow-50 py-2 px-3 relative w-ful">
        <h3 class="text-base font-semibold text-yellow-800">Instagram Session Has Expired.</h3>
        <div class="mt-1 text-sm text-yellow-700">
          <p>You cannot reply due to 24 hours message window restriction.</p>
        </div>
      </div>
    </template>

    <!-- Session Message Input -->
    <div class="composerBox shadow bg-white" v-else>
      <!-- Image Box -->
      <div v-if="form.mediaUrl" class="p-0 border rounded-lg overflow-hidden w-auto inline-block relative">
        <!-- Close Icon -->
        <div class="absolute top-1 right-1 text-lg text-gray-500 cursor-pointer" @click="onRemoveUploadedMedia" style="z-index: 9999">
          <!-- <i class="fa-solid fa-circle-xmark bg-white"></i> -->
          <img class="h-5" src="@/assets/images/chatBox/close-btn.png" />
        </div>

        <!-- Image -->
        <img v-if="form.type.toUpperCase() == 'IMAGE'" :src="form.mediaUrl" style="max-width: 500px; max-height: 150px" />

        <!-- Video -->
        <templte v-else-if="form.type.toUpperCase() == 'VIDEO'">
          <video controls style="max-width: 300px; max-height: 1024px">
            <source :src="form.mediaUrl" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </templte>

        <!-- Document -->
        <div class="flex bg-gray-50 p-3" v-else-if="form.type.toUpperCase() == 'DOCUMENT'">
          <div class="">
            <i class="fa-solid fa-file text-4xl text-gray-400"></i>
          </div>
          <div class="pl-3 pt-1">
            <div class="text-xs text-gray-500">Document</div>
            <div class="text-gray-600 text-sm">{{ form.mediaName }}</div>
          </div>
        </div>
      </div>

      <!-- Text Input -->
      <div class="pl-1 pt-1">
        <el-input id="messageComposerInputBox" :disabled="sendingMessage" type="textarea" v-model="form.text" rows="2" placeholder="Type Your Message Here" @keyup.enter.native="onKeyUp"></el-input>
      </div>

      <!-- Bottom Buttons -->
      <div class="flex bottomBar">
        <div class="flex-1 ml-2 mt-1 text-xl text-gray-500">
          <!-- Send Smile -->
          <el-popover v-model="emojiPicker" popper-class="customTooltip p-0 border-none" trigger="click">
            <picker type="hidden" :showPreview="false" :perLine="10" :exclude="['flags']" @select="onEmojiSelected" :native="true" set="emojione" />
            <i class="fa-regular fa-face-smile pr-2 cursor-pointer hover:text-primary-700" slot="reference"></i>
            <!-- <el-button size="small" type="primary" plain class="whatsAppUserIconBtn" >
            <i class="fa fa-smile-o" aria-hidden="true"></i>
          </el-button> -->
          </el-popover>

          <!-- Send Attachment -->
          <el-dropdown trigger="click" @command="onUploadButtonClicked">
            <span class="el-dropdown-link"> <i class="fa-solid fa-paperclip text-lg cursor-pointer hover:text-primary-700"></i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="image-upload"><i class="fa-regular fa-image"></i> Image</el-dropdown-item>
              <el-dropdown-item command="video-upload"><i class="fa-regular fa-video"></i> Video</el-dropdown-item>
              <el-dropdown-item command="document-upload"><i class="fa-regular fa-file"></i> Document</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- WhatsApp Template Select Button -->
          <template v-if="channelInfo.type == 'whatsApp'">
            &nbsp;
            <el-tooltip effect="dark" content="Send Template Message" placement="bottom">
              <i @click="showTemplateMessagePopup" class="fa-solid fa-bolt text-lg cursor-pointer hover:text-primary-700"></i>
            </el-tooltip>
          </template>
        </div>
        <div class="flex-none">
          <el-button type="primary" size="small" @click="onSendMessage" :loading="sendingMessage">SEND</el-button>
        </div>
      </div>

      <!-- Image Uploader -->
      <input ref="messageImageUploadInput" @change="onUploadImage" class="hidden" type="file" multiple="false" accept="image/*" />
      <input ref="messageVideoUploadInput" @change="onUploadImage" class="hidden" type="file" multiple="false" accept="video/mp4, video/3gp" />
      <input ref="messageDocumentUploadInput" @change="onUploadImage" class="hidden" type="file" multiple="false" accept="text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
    </div>

    <!-- Template Message Popup -->
    <SendTemplateMessagePopup :templateSelector="true" :isSendingFromChatBox="true" @onNewMessageSent="onNewTemplateMesageComposed" ref="SendTemplateMessageDialog"></SendTemplateMessagePopup>
  </div>
</template>

<style lang="scss" src="./messageComposer.scss"></style>

<script>
import messageComposer from './messageComposerComponent';
export default messageComposer;
</script>
