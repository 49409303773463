const axios = require('axios');
const { chatServiceUrl, buildAuthHeaders } = require('./settings.js');

exports.getList = function (params) {
  return axios.post(chatServiceUrl + '/conversation/list', params, {
    headers: buildAuthHeaders()
  });
};

exports.getPastTicketListOfCustomer = function (params) {
  return axios.post(chatServiceUrl + '/conversation/customer/list', params, {
    headers: buildAuthHeaders()
  });
};

exports.getPastTicketCountOfCustomer = function (params) {
  return axios.post(chatServiceUrl + '/conversation/customer/count', params, {
    headers: buildAuthHeaders()
  });
};

exports.getDetails = function (id) {
  return axios.get(chatServiceUrl + `/conversation/${id}`, {
    headers: buildAuthHeaders()
  });
};

exports.setStatus = function (params) {
  return axios.post(chatServiceUrl + '/conversation/status', params, {
    headers: buildAuthHeaders()
  });
};

exports.updateTags = function (params) {
  return axios.post(chatServiceUrl + '/conversation/tags', params, {
    headers: buildAuthHeaders()
  });
};

exports.assignAgent = function (params) {
  return axios.post(chatServiceUrl + '/conversation/assign-agent', params, {
    headers: buildAuthHeaders()
  });
};

// Search Conversation by Customer
exports.searchConversationByCustomer = function (params) {
  return axios.post(chatServiceUrl + '/conversation/search/customer', params, {
    headers: buildAuthHeaders()
  });
};

// Search Conversation by Conversation
exports.searchConversationByConversation = function (params) {
  return axios.post(chatServiceUrl + '/conversation/search/conversation', params, {
    headers: buildAuthHeaders()
  });
};

// Search Conversation by Message
exports.searchMessages = function (params) {
  return axios.post(chatServiceUrl + '/conversation/search/message', params, {
    headers: buildAuthHeaders()
  });
};
