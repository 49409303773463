import ConversationListComponent from '@/components/conversationMainPage/conversationList/conversationList';
import ChatBoxComponent from '@/components/conversationMainPage/chatBox/chatBox';
import socketService from '@/services/socketService';

export default {
  name: 'campaignList',
  components: {
    ConversationListComponent,
    ChatBoxComponent
  },
  data() {
    return {
      pageLoading: false,

      conversationList: [],
      selectedConvId: null
    };
  },
  methods: {
    onConversationSelect(conversationId) {
      // Remove Old Message Listener.
      let topicName = 'conversation-detail-' + this.selectedConvId;
      socketService.removeListener(topicName);

      // Load Conversation.
      this.selectedConvId = conversationId;
      this.$refs.chatBox.loadConversation(conversationId);

      // Add Message Listener.
      topicName = 'conversation-detail-' + this.selectedConvId;
      console.log('Added socket listner:', topicName);
      let obj = this;
      socketService.addListener(topicName, (payload) => {
        obj.$refs.chatBox.onNewMesageFromSocket(payload);
      });
    },

    // Open Closed Conversation and removed from list.
    onConversationRemoved(data) {
      let conversationId = data.current;

      if (this.selectedConvId != conversationId) {
        return;
      }

      // Remove existing socket listener.
      if (this.selectedConvId) {
        let topicName = 'conversation-list-' + this.selectedConvId;
        socketService.removeListener(topicName);
      }

      this.$refs.chatBox.removeConversationDisplay();
    },

    onShowPastTicket(data) {
      let conversationId = data['id'];
      // conversationId = 'bab56e13-91f1-4fb9-8f17-5c370b1aa5de';
      this.onConversationSelect(conversationId);
    }
  },

  mounted() {
    // Init Socket, if not initialized.
    socketService.init(this.$store.state.token.companyId);

    // Add conversations listener.
    console.log('Add conversation list server listener.');
    socketService.addListener('conversation-list', (payload) => {
      this.$refs.conversationList.onSocketPayload(payload);
    });
  },

  beforeDestroy() {
    console.log('Remove conversation list server listener.');
    socketService.removeListener('conversation-list');
  }
};
