const axios = require('axios');
const { chatServiceUrl, buildAuthHeaders } = require('./settings.js');

exports.getList = function (params) {
  return axios.get(chatServiceUrl + '/agent/list', params, {
    headers: buildAuthHeaders()
  });
};

// const axios = require('axios');
// const { chatServiceApiServerlessUrl, buildAuthHeaders } = require('./settings.js');

// exports.getList = function (params) {
//   return axios.get(chatServiceApiServerlessUrl + '/agent/list', params, {
//     headers: buildAuthHeaders()
//   });
// };
