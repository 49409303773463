import customerService from '@/services/customerService';
import conversationService from '@/services/conversationService';
import { marketingDashboardURL } from '@/services/settings';
import companySettingService from './../../../services/companySettingService';
import _ from 'lodash';

export default {
  name: 'conversationMeta',
  props: {
    ticketId: {
      required: true
    },
    customer: {
      required: true
    },
    incomingTicketTags: {
      default: [],
      type: Array
    }
  },
  components: {},
  data() {
    return {
      marketingDashboardURL: marketingDashboardURL,

      selectedTab: 'overview',

      // Orders Card
      ordersLoader: true,
      ordersError: false,
      ordersList: null,

      // Tags Card
      tagsMgt: {
        ticketTags: [],
        selectedTag: null,
        availableTagsList: []
      },

      // Previous Tickets Card
      pastTickets: {
        loading: true,
        list: null,
        pagination: {
          pageSize: 10,
          pageNumber: 0
        },
        total: 0,
        error: false
      }
    };
  },
  methods: {
    async onTagChange() {
      if (this.selectedTab === 'tickets') {
        if (this.pastTickets.list === null) {
          this.fetchPastTickets();
        }
      }
    },

    async fetchPastOrders() {
      try {
        let result = await customerService.getPastOrders({
          customer_id: this.customer.id
        });

        this.ordersList = result.data;
      } catch (err) {
        this.orderFetchingFailureError = true;
        this.ordersError = true;
      } finally {
        this.ordersLoader = false;
      }
    },

    //#region ------------ Start: Past Tickets ------------

    async fetchPastTickets() {
      try {
        this.pastTickets.error = false;
        this.pastTickets.loading = true;

        let result = await conversationService.getPastTicketListOfCustomer({
          customerId: this.customer.id,
          pagination: this.pastTickets.pagination
        });

        this.pastTickets.list = result.data;
      } catch (err) {
        this.pastTickets.error = true;
        console.error('Error fetching past tickets', err);
      } finally {
        this.pastTickets.loading = false;
      }
    },

    async onPastTicketClick(ticket) {
      // alert(JSON.stringify(ticket));
      this.$emit('onShowPastTicket', ticket);
    },

    //#endregion ------------ End: Past Tickets ------------

    // #region ------------ Start: Tags ------------

    async fetchAvailableTags() {
      try {
        const response = await companySettingService.getTagsList();
        let tagList = response.data ? JSON.parse(response.data) : [];
        console.log('tagList', tagList);

        // Order By Name.
        tagList = tagList.sort((a, b) => a.name.localeCompare(b.name));
        this.tagsMgt.availableTagsList = tagList;

        // Parse & set tickets.
        this.tagsMgt.ticketTags = [];
        if (this.incomingTicketTags) {
          this.incomingTicketTags.forEach((tagId) => {
            let existingTag = _.find(this.tagsMgt.availableTagsList, { id: tagId });
            if (existingTag) {
              this.tagsMgt.ticketTags.push(existingTag);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async onAddTag() {
      try {
        let tag = _.find(this.tagsMgt.availableTagsList, { id: this.tagsMgt.selectedTag });

        // If tag already exists, do nothing.
        if (!_.find(this.tagsMgt.ticketTags, { id: this.tagsMgt.selectedTag })) {
          // Update tag in db.
          conversationService
            .updateTags({
              id: this.ticketId,
              action: 'add',
              tagId: this.tagsMgt.selectedTag
            })
            .catch((err) => {
              console.error('Error adding tag to ticket', err);

              this.errorToast('Failed to add tag. Please contact support.');

              // Remove tag from UI.
              this.tagsMgt.ticketTags = _.filter(this.tagsMgt.ticketTags, (item) => {
                return item.id != tag.id;
              });
            });

          // Update tag in UI.
          this.tagsMgt.ticketTags.push(tag);
        }

        let component = this;
        requestAnimationFrame(() => {
          component.tagsMgt.selectedTag = null;
        });
      } catch (err) {
        console.error('Error adding tag to customer', err);
      }
    },

    async onRemoveTag(tag) {
      try {
        // Update tag in db.
        conversationService
          .updateTags({
            id: this.ticketId,
            action: 'remove',
            tagId: tag.id
          })
          .catch((err) => {
            console.error('Error removing tag from ticket', err);

            this.errorToast('Failed to remove tag. Please contact support.');

            // Add tag back to UI.
            this.tagsMgt.ticketTags.push(tag);
          });

        // Update tag in UI.
        this.tagsMgt.ticketTags = _.filter(this.tagsMgt.ticketTags, (item) => {
          return item.id != tag.id;
        });
      } catch (err) {
        console.error('Error removing tag from customer', err);
      }
    }

    // #endregion ------------ End: Tags ------------
  },

  mounted() {
    this.fetchPastOrders();
    this.fetchAvailableTags();
  }
};
