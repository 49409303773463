import conversationService from '@/services/conversationService';
import agentService from '@/services/agentService';
import MessageComposerComponent from '@/components/messageComposer/messageComposer';
import ConversationMetaComponent from '@/components/conversationMainPage/conversationMeta/conversationMeta';
import MediaPreviewPopup from '@/components/mediaPreviewPopup';

import WhatsAppMessageBlock from './messageBlock/whatsAppMessageBlock.vue';
import InstagramMessageBlock from './messageBlock/instagramMessageBlock.vue';
import FacebookMessageBlock from './messageBlock/facebookMessageBlock.vue';

import moment from 'moment';
import * as _ from 'lodash';

export default {
  name: 'chatBox',

  components: {
    MessageComposerComponent,
    ConversationMetaComponent,
    MediaPreviewPopup,
    WhatsAppMessageBlock,
    InstagramMessageBlock,
    FacebookMessageBlock
  },

  data() {
    return {
      moment: moment,
      loading: false,
      conversationId: null,

      pageOptions: {
        pageSize: 20,
        pageNumber: 1,
        total: null
      },

      conversation: null,
      customer: null,
      channel: null,
      messageList: [],

      // Agent Management Variables.
      agentList: [],

      // Session Expiry Variables.
      lastCustomerMessageAt: null,
      sessionExpired: false,
      sessionTimeLeftDisplayText: null,
      sessionExpiryTimer: null,

      currentMessageListenerId: null,
      messageBoxResizeObserver: null
    };
  },

  methods: {
    async loadConversation(conversationId) {
      try {
        this.loading = true;

        this.conversationId = conversationId;

        let response = await conversationService.getDetails(conversationId);

        this.conversation = response.data.conversation;
        this.customer = response.data.customer;
        this.channel = response.data.channel;

        // Format Message List.
        for (let i = 0; i < response.data.messageList.length; i++) {
          response.data.messageList[i] = this._formatMessageForDisplay(response.data.messageList[i]);
        }
        this.messageList = response.data.messageList;

        // Start Timer.
        this.lastCustomerMessageAt = this.conversation.last_customer_message_at;
        this.checkSessionExpiryTimer();
        this.clearSessionExpiryInterval();
        // this.sessionExpiryTimer = setInterval(this.checkSessionExpiryTimer, 2500);

        this.loading = false;

        // Set auto scroll for new messages.
        requestAnimationFrame(() => {
          if (this.messageBoxResizeObserver) {
            let element = document.getElementById('messageListContainer');
            if (element) {
              this.messageBoxResizeObserver = new ResizeObserver(this.onMessageListBoxSizeChange).observe(element);
            }
          }
          for (let i = 0; i < 4; i++) {
            setTimeout(() => {
              this.onMessageListBoxSizeChange(true);
            }, (i + 1) * 400);
          }
        });
      } catch (err) {
        this.reportError(err);
        this.errorToast('Something went wrong. Please contact support.');
      }
    },

    async removeConversationDisplay() {
      this.conversationId = null;
    },

    async onNewMesageComposed() {
      requestAnimationFrame(() => {
        this.onMessageListBoxSizeChange(true);
      });
    },

    async onNewMesageFromSocket(payload) {
      console.log('Socket: ChatDetail: New Message:', payload);

      let action = payload.action;
      if (action == 'new') {
        let message = payload.data;
        let msg = _.find(this.messageList, (ms) => {
          return ms.id == message.id;
        });

        // If message already present, don't add.
        if (msg) {
          return;
        }

        // Add message to queue.
        message = this._formatMessageForDisplay(message);
        this.messageList.push(message);

        // If message is from customer, remove session from expired state.
        if (payload.data.sender_type == 'customer') {
          this.sessionExpired = false;
        }

        // Scroll to bottom of the page.
        requestAnimationFrame(() => {
          this.playNewNotificationSound();

          // let element = document.getElementById('messageListContainer');
          // let heightToCompare = element.scrollHeight - element.offsetHeight;
          // let isAtBottomOfChat = element.scrollTop > heightToCompare - 2000 && element.scrollTop < heightToCompare + 2000;
          // if (isAtBottomOfChat) {
          for (let i = 0; i < 3; i++) {
            setTimeout(() => {
              this.onMessageListBoxSizeChange(false);
            }, (i + 1) * 400);
          }
          // }
        });
      }

      // If Message Status Change.
      else if (action == 'status-change') {
        let msg = _.find(this.messageList, (ms) => {
          return ms.id == payload.data.id;
        });
        if (msg) {
          msg.status = payload.data.data;
        }
      }
    },

    _formatMessageForDisplay(message) {
      message.created_at_text = moment(parseInt(message.created_at)).format(`Do MMM, hh:mm A`);
      return message;
    },

    async showMediaPreviewPopup(args) {
      this.$refs.mediaPreviewPopup.showPopup(args.mediaUrl, args.mediaType);
    },

    //#region --- Start: Status Mgt Methods --------------

    async onCloseConversation() {
      try {
        await conversationService.setStatus({
          id: this.conversation.id,
          status: 'closed'
        });
        this.conversation.status = 'closed';
        this.successToast('Conversation Closed.');
      } catch (err) {
        console.log('Failed to close conversation', err);
        this.errorToast('Failed to close conversation. Please contact support.');
      }
    },

    async onReOpenConversation() {
      try {
        await conversationService.setStatus({
          id: this.conversation.id,
          status: 'open'
        });
        this.conversation.status = 'open';
        this.successToast('Conversation Opened.');
      } catch (err) {
        console.log('Failed to re-open conversation', err);
        this.errorToast('Failed to re-open conversation. Please contact support.');
      }
    },

    //#endregion ---------------- End: Status Mgt Methods -----------------

    //#region --- Start: Agent Mgt Methods --------------

    async getAgentList() {
      try {
        let result = await agentService.getList();
        this.agentList = result.data;
      } catch (err) {
        console.error('Failed to fetch agent list', err);
      }
    },

    async onAssignChatToAgent() {
      try {
        await conversationService.assignAgent({
          conversationId: this.conversation.id,
          agentId: this.conversation.agent_id
        });

        this.successToast('Agent Assigned.');
      } catch (err) {
        this.errorToast('Failed to assign agent. Please contact support.');
        console.error('Failed to assign agent', err);
      }
    },

    //#endregion ---------------- End: Agent Mgt Methods -----------------

    checkSessionExpiryTimer() {
      console.log('Session ExpiryTimer Check.', this.lastCustomerMessageAt);

      // Set Default.
      this.sessionExpired = false;

      let expiryTime = moment(parseInt(this.lastCustomerMessageAt)).add(24, 'hours');

      this.sessionTimeLeftDisplayText = expiryTime.format('Do MMM, HH:MM A');
      if (moment().valueOf() > expiryTime.valueOf()) {
        this.sessionExpired = true;
      }
    },

    clearSessionExpiryInterval() {
      if (this.sessionExpiryTimer) {
        clearInterval(this.sessionExpiryTimer);
      }
    },

    onMessageListBoxSizeChange() {
      let element = document.getElementById('messageListContainer');
      if (element) {
        element.scroll(0, element.scrollHeight);
      }
    },

    onShowPastTicket(data) {
      console.log('Show Ticket From Parent: ', data);
      this.$emit('onShowPastTicket', data);
    }
  },

  mounted() {
    this.getAgentList();
  }
};
