<template>
  <div class="conversationRightSideMeta">
    <el-tabs v-model="selectedTab" @tab-click="onTagChange">
      <el-tab-pane label="  Overview  " name="overview">
        <!-- Customer Details -->
        <div class="border border-gray-200 bg-white rounded-md overflow-hidden">
          <div class="text-base px-3 py-1.5 text-gray-900 border-b flex bg-gray-50">
            <div class="flex-1 text-base text-sm font-semibold">Customer</div>
            <div>
              <a :href="`${marketingDashboardURL}/customer/detail?id=${customer.id}`" target="_blank">
                <i class="fa-solid fa-arrow-up-right-from-square text-sm text-primary-600 cursor-pointer"></i>
              </a>
            </div>
          </div>
          <div class="p-3">
            <!-- Name -->
            <div class="text-xs text-gray-600">Name</div>
            <div class="text-sm text-gray-800">{{ customer.name ? customer.name : 'Not Available' }}</div>

            <!-- Contact -->
            <div class="text-xs text-gray-600 mt-2">Phone No</div>
            <div class="text-sm">{{ customer.mobile ? customer.mobile : 'Not Available' }}</div>

            <!-- Email -->
            <div class="text-xs text-gray-600 mt-2">Email</div>
            <div class="text-sm">{{ customer.email ? customer.email : 'Not Available' }}</div>
          </div>
        </div>

        <!-- Tags -->
        <div class="border border-gray-200 bg-white rounded-md mt-4 overflow-hidden">
          <div class="text-base px-3 py-2 font-semibold text-gray-900 flex bg-gray-50 border-b">
            <div class="flex-1"><i class="fa-regular fa-tag pt-1" />&nbsp; Tags</div>
            <div>
              <a href="/settings/tags" target="_blank">
                <el-button type="text" size="mini" @click="onAddTagClick"
                  >Add Tags
                  <i class="fa-solid fa-arrow-up-right"></i>
                </el-button>
              </a>
            </div>
          </div>

          <div>
            <!-- Tags List -->
            <div style="font-size: 11px; line-height: 18px">
              <span class="my-1 mx-1 py-1 px-2 border-t border rounded float-left" v-for="(tag, tagIndex) in tagsMgt.ticketTags" :key="tagIndex">
                <i class="fa-solid fa-circle" :style="{ color: tag.color, 'font-size': '9px' }"></i>
                <span class="">{{ tag.name }}</span>
                <i class="fa-solid fa-xmark font-semibold cursor-pointer hover:text-red-600" @click="onRemoveTag(tag, tagIndex)"></i>
              </span>
            </div>

            <!-- Add Tag Button -->
            <el-select class="w-24 ml-1 my-1" v-model="tagsMgt.selectedTag" placeholder="Add Tag" @change="onAddTag" size="mini">
              <el-option v-for="(tag, tagIndex) in tagsMgt.availableTagsList" :key="tagIndex" :label="tag.name" :value="tag.id"> <i class="fa-solid fa-circle" :style="{ color: tag.color, 'font-size': '9px' }"></i> {{ tag.name }}</el-option>
            </el-select>
          </div>
        </div>

        <!-- Order History -->
        <div class="border border-gray-200 bg-white rounded-md mt-4 overflow-hidden">
          <div class="text-base px-3 py-2 font-semibold text-gray-900 flex bg-gray-50 border-b">Last 5 Orders</div>

          <div v-if="ordersLoader" v-loading="true" class="h-24"></div>
          <div v-else-if="ordersError" class="py-6 text-center text-red-800 text-xs">Failed To Fetch Orders.</div>

          <template v-else>
            <!-- No Orders Text -->
            <div class="py-4 text-center text-sm text-gray-500 border-t" v-if="ordersList.length == 0">No Orders Found.</div>

            <!-- Order List -->
            <div v-else>
              <div class="p-3 border-t flex" v-for="(order, orderIndex) in ordersList" :key="orderIndex">
                <span class="text-sm text-black">Order {{ order.number }} </span>
                <div class="flex-1 text-right">
                  <span class="text-xs text-gray-600 leading-5">{{ order.timestamp }}</span>
                  &nbsp;
                  <a :href="order.url" target="_blank">
                    <i class="fa-solid fa-arrow-up-right-from-square text-primary-600 cursor-pointer"></i>
                  </a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </el-tab-pane>

      <!-- Tickets -->
      <el-tab-pane label="Tickets" name="tickets">
        <!-- Order History -->
        <div class="border border-gray-200 bg-white rounded-md mt-4 overflow-hidden">
          <div v-if="pastTickets.loading" v-loading="true" class="h-24"></div>
          <div v-else-if="pastTickets.error" class="py-6 text-center text-red-800 text-xs">Failed To Previous Tickets.</div>
          <template v-else>
            <div class="px-3 py-2 flex cursor-pointer hover:bg-gray-50" v-for="(ticket, ticketIndex) in pastTickets.list" :key="ticketIndex" @click="onPastTicketClick(ticket)">
              <div class="flex-1">
                <span class="text-sm text-gray-600">{{ getDisplayTime(ticket.created_at) }}</span>
                <div class="text-xs text-gray-500">
                  <template v-if="ticket.agent_info"> Handled By {{ ticket.agent_info.first_name + ' ' + ticket.agent_info.last_name }} </template>
                  <template v-else> Not assigned to anyone. </template>
                </div>
              </div>
              <div class="text-sm text-black mt-1">
                <span v-if="ticket.status == 'open'" class="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">OPEN</span>
                <span v-else class="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">CLOSED</span>
              </div>
            </div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style lang="scss" src="./conversationMeta.scss"></style>

<script>
import conversationMetaComponent from './conversationMetaComponent';
export default conversationMetaComponent;
</script>
