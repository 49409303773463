import { Picker } from 'emoji-mart-vue';
import messageService from '@/services/messageService';
import mediaAssetService from '@/services/mediaAssetService';
import SendTemplateMessagePopup from '@/components/sendTemplateMessagePopup/sendTemplateMessagePopup';

export default {
  name: 'messageComposer',
  components: {
    Picker,
    SendTemplateMessagePopup
  },
  props: {
    conversationId: {
      required: true
    },
    channelId: {
      required: true
    },
    channelInfo: {
      required: true
    },
    customer: {
      required: true
    },
    sessionExpired: {
      required: true
    }
  },
  data() {
    return {
      sendingMessage: false,
      emojiPicker: false,
      whatsAppEmojiSelected: null,

      formTemplate: {
        text: '',
        type: 'text',
        mediaUrl: null,
        mediaName: null
      },
      form: null,
      uploadType: null,

      disabledFeatures: {
        whatsAppTemplatesPicker: true
      }
    };
  },
  methods: {
    onEmojiSelected(emoji) {
      console.log('Emoji Selected', emoji.native);

      // Fetch Current Position
      let position = document.getElementById('messageComposerInputBox').selectionStart;
      position = position ? position : 0;

      let insert = function (input, position, textToInsert) {
        input = input ? input : '';
        return `${input.slice(0, position)}${textToInsert}${input.slice(position)}`;
      };

      this.form.text = insert(this.form.text, position, emoji.native);
      this.emojiPicker = false;
    },

    //#region ------------------ Media Upload Methods ------------------------

    onUploadButtonClicked(command) {
      if (command == 'image-upload') {
        this.uploadType = 'image';
        this.$refs.messageImageUploadInput.value = '';
        this.$refs.messageImageUploadInput.click();
      } else if (command == 'video-upload') {
        this.uploadType = 'video';
        this.$refs.messageVideoUploadInput.value = '';
        this.$refs.messageVideoUploadInput.click();
      } else if (command == 'document-upload') {
        this.uploadType = 'document';
        this.$refs.messageDocumentUploadInput.value = '';
        this.$refs.messageDocumentUploadInput.click();
      }
    },

    async onUploadImage(event) {
      const loading = this.$loading({
        lock: true,
        text: 'Uploading',
        spinner: 'el-icon-loading',
        background: '#f5f7fad9'
      });

      try {
        let file = event.target.files[0];

        // Check For File Size.
        let fileSize = parseInt(file.size / 1024 / 1024); // MB
        if (this.uploadType == 'image') {
          // If image, max 5Mb Allowed.
          if (fileSize > 5) {
            this.warningToast('Max allowed file size is 5MB.');
            loading.close();
            return;
          }
        } else if (this.uploadType == 'video') {
          // Else, max 15Mb Allowed.
          if (fileSize > 15) {
            this.warningToast('Max allowed file size is 15MB.');
            loading.close();
            return;
          }
        } else if (this.uploadType == 'document') {
          // Else, max 15Mb Allowed.
          if (fileSize > 90) {
            this.warningToast('Max allowed file size is 90MB.');
            loading.close();
            return;
          }
        }

        // Upload Image - API.
        this.uploadingImage = true;
        let formData = new FormData();
        formData.append('file', file);
        let result = await mediaAssetService.uploadImageAsset(formData, this);

        // Set UI Variables.
        this.form.type = this.uploadType;
        this.form.mediaUrl = result.data[0].url;
        this.form.mediaName = result.data[0].originalFileName;
      } catch (err) {
        console.log('Image upload failed', err);
        this.errorToast('Failed to upload image, plase contact support.');
      } finally {
        loading.close();
      }
    },

    onRemoveUploadedMedia() {
      this.form.type = 'text';
      this.form.mediaUrl = null;
      this.form.mediaName = null;
    },

    //#endregion ------------------ Media Upload Methods ------------------------

    //#region ------------------ Start: Template Message Popup Mgt ------------------------

    showTemplateMessagePopup() {
      this.$refs.SendTemplateMessageDialog.showPopup(this.customer, this.conversationId);
    },

    //#endregion ------------------ Start: Template Message Popup Mgt ------------------------

    async onKeyUp(event) {
      if (event.ctrlKey) {
        this.onSendMessage();
      } else {
        console.log('Just Enter, Ignore.');
      }
    },

    async onSendMessage() {
      try {
        // Don't allow more than 1k texts.
        if (this.form.text.length > 4096) {
          this.warningToast('Maximum 4096 characters are allowed in message.');
          return;
        }

        this.sendingMessage = true;

        // Send Message
        let response = await messageService.sendSessionMessage({
          conversationId: this.conversationId,
          input: {
            type: this.form.type,
            text: this.form.text,
            mediaName: this.form.mediaName,
            mediaUrl: this.form.mediaUrl
          },
          customerId: this.customer.id,
          senderType: 'agent',
          agentId: this.$store.state.token.userId
        });

        // Add message sent to message list.
        let messageObj = response.data;
        this.$emit('onNewMesageComposed', messageObj);

        // Clear Form.
        this.form = JSON.parse(JSON.stringify(this.formTemplate));
      } catch (err) {
        console.error('Failed to send message.', err);
        this.errorToast('Failed to send message, plase contact support.');
      } finally {
        this.sendingMessage = false;
      }
    },

    onNewTemplateMesageComposed() {
      this.$emit('onNewMesageComposed', null);
    }
  },

  created() {
    this.form = JSON.parse(JSON.stringify(this.formTemplate));
  },

  mounted() {
    this.form = JSON.parse(JSON.stringify(this.formTemplate));
  }
};
