<template>
  <div class="flex-1 message-item-container" :class="{ 'from-agent': message.sender_type != 'customer' }">
    <div class="message-item overflow-hidden" :style="(message.type.toUpperCase() == 'UNKNOWN' ? 'background: #ee4040 !important; color: white !important;' : '', message.is_template_message ? 'width: 400px' : 'auto')">
      <!-- Template Notification -->
      <div v-if="message.is_template_message" class="border-b border-primary-100 py-2 text-center mb-2">Template Message</div>

      <!-- Instagram story reply icon. -->
      <div class="px-2 pt-2 -mb-1 text-xs" v-if="message.content.instagramMessageType == 'story_reply'">
        <span class="instagram_color"><i class="fa-light fa-share"></i> Story Mention </span>
      </div>

      <div class="p-2">
        <!-- Header: Text -->
        <template v-if="message.type.toUpperCase() == 'TEXT'">
          <div class="text-gray-400 mb-2" v-if="message.content.headerText">
            {{ message.content.headerText }}
          </div>
        </template>

        <!-- Header: Image -->
        <template v-if="message.type.toUpperCase() == 'IMAGE'">
          <img @click="showMediaPreviewPopup(message.content.mediaUrl, 'image')" v-if="!message.is_template_message" class="mb-2 rounded-md cursor-pointer" :src="message.content.mediaUrl" style="max-width: 350px; max-height: 300px" />
          <!-- For Template Message, image width will be 100% -->

          <img @click="showMediaPreviewPopup(message.content.mediaUrl, 'image')" v-else class="ravi-test mb-2 rounded-md cursor-pointer" :src="message.content.mediaUrl" style="width: 100%" />
        </template>

        <!-- Header: Video -->
        <div class="rounded-md overflow-hidden mb-2 cursor-pointer" v-else-if="message.type.toUpperCase() == 'VIDEO'">
          <video controls style="max-width: 350px; max-height: 300px" @click="showMediaPreviewPopup(message.content.mediaUrl, 'video')">
            <source :src="message.content.mediaUrl" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <!-- Header: Document -->
        <div class="flex bg-gray-50 p-3 mb-2 rounded-lg" v-else-if="message.type.toUpperCase() == 'DOCUMENT'">
          <div class="">
            <i class="fa-solid fa-file text-4xl text-gray-500"></i>
          </div>
          <div class="pl-3 pt-1 flex-1 overflow-hidden">
            <div class="text-xs text-gray-500">Document</div>
            <div class="text-gray-600 text-sm">{{ message.content.mediaName }}</div>
          </div>
          <div class="pl-4 pt-1">
            <a :href="message.content.mediaUrl" target="_blank">
              <i class="fa-light fa-circle-arrow-down text-3xl text-primary-500"></i>
            </a>
          </div>
        </div>

        <!-- Header: Sticker -->
        <div class="rounded-md overflow-hidden mb-2" v-else-if="message.type.toUpperCase() == 'STICKER'">
          <div class="text-gray-500 text-xs mb-1">Sticker Shared</div>
          <img :src="message.content.mediaUrl" style="max-width: 300px; max-height: 1024px" />
        </div>

        <!-- Contacts Render -->
        <div class="rounded-md overflow-hidden mb-2" v-else-if="message.type.toUpperCase() == 'CONTACTS'">
          <div class="text-gray-500 text-xs">Contacts Shared</div>
          <div class="bg-white px-3 py-2 mt-2 rounded-md overflow-hidden" v-for="(contact, contactIndex) in message.content.contact" :key="contactIndex">
            <div class="text-gray-800 text-sm">{{ contact.name }}</div>
            <div class="text-gray-500 text-xs">{{ contact.phone }}</div>
          </div>
        </div>

        <!-- Quick Button Reply -->
        <div class="rounded-md overflow-hidden mb-2" v-else-if="message.type.toUpperCase() == 'BUTTON'">
          <div class="text-gray-500 text-xs">Button Click</div>
        </div>

        <div class="whitespace-pre-line">{{ message.content.text }}</div>

        <!-- Footer: Text -->
        <div class="text-gray-400 mt-2" v-if="message.content.footerText">
          {{ message.content.footerText }}
        </div>

        <!-- Buttons -->
        <div class="mt-4" v-if="message.content.buttons && message.content.buttons.length > 0">
          <div class="p-2 bg-white mt-2 rounded-md" v-for="(button, buttonIndex) in message.content.buttons" :key="buttonIndex">
            <span class="text-blue-500"> {{ button.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'whatsAppMessageBlock',
  props: {
    customer: {
      type: Object,
      required: true
    },
    channel: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    }
  },

  methods: {
    showMediaPreviewPopup(mediaUrl, mediaType) {
      this.$emit('showMediaReviewPopup', {
        mediaUrl,
        mediaType
      });
    }
  }
};
</script>
