<template>
  <div class="flex-1 message-item-container" :class="{ 'from-agent': message.sender_type != 'customer' }">
    <div class="message-item overflow-hidden" :style="(message.type.toUpperCase() == 'UNKNOWN' ? 'background: #ee4040 !important; color: white !important;' : '', message.is_template_message || post ? 'width: 350px' : 'auto')">
      <!-- Template Notification -->
      <div v-if="message.is_template_message" class="border-b border-primary-100 py-2 text-center mb-2">Template Message</div>

      <!-- Instagram Story - reply icon. -->
      <div class="px-2 py-2 -mb-1 text-xs" v-if="message.content.instagramMessageType == 'story_reply'">
        <span class="instagram_color"><i class="fa-light fa-share"></i> Story Mention </span>
      </div>

      <!-- Instagram Post - Brand Header reply icon. -->
      <div class="px-2 py-2 text-sm" v-if="message.content.instagramMessageType == 'comment'">
        <i class="fa-brands fa-instagram text-pink-600 text-bold"></i>
        <span class="font-normal text-gray-800 font-semibold"> {{ channel.meta.instagramUsername }} </span>
      </div>

      <!-- Header - img/video/document -->
      <template>
        <!-- Header: Text -->
        <template v-if="message.type.toUpperCase() == 'TEXT'">
          <div class="text-gray-400 mb-2" v-if="message.content.headerText">
            {{ message.content.headerText }}
          </div>
        </template>

        <!-- Header: Image -->
        <template v-if="message.type.toUpperCase() == 'IMAGE'">
          <template v-if="['story_reply', 'comment'].indexOf(message.content.instagramMessageType) >= 0"></template>
          <img @click="showMediaPreviewPopup(message.content.mediaUrl, 'image')" v-if="!message.is_template_message" class="mb-2 cursor-pointer" :src="message.content.mediaUrl" style="width: 100%" />
          <template v-else>
            <img @click="showMediaPreviewPopup(message.content.mediaUrl, 'image')" v-if="!message.is_template_message" class="mb-2 cursor-pointer" :src="message.content.mediaUrl" style="max-width: 350px; max-height: 300px" />
          </template>
        </template>

        <!-- Header: Video -->
        <div class="rounded-md overflow-hidden mb-2 cursor-pointer" v-else-if="message.type.toUpperCase() == 'VIDEO'">
          <video controls style="max-width: 350px; max-height: 300px" @click="showMediaPreviewPopup(message.content.mediaUrl, 'video')">
            <source :src="message.content.mediaUrl" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <!-- Header: Document -->
        <div class="flex bg-gray-50 p-3 mb-2 rounded-lg" v-else-if="message.type.toUpperCase() == 'DOCUMENT'">
          <div class="">
            <i class="fa-solid fa-file text-4xl text-gray-500"></i>
          </div>
          <div class="pl-3 pt-1 flex-1 overflow-hidden">
            <div class="text-xs text-gray-500">Document</div>
            <div class="text-gray-600 text-sm">{{ message.content.mediaName }}</div>
          </div>
          <div class="pl-4 pt-1">
            <a :href="message.content.mediaUrl" target="_blank">
              <i class="fa-light fa-circle-arrow-down text-3xl text-primary-500"></i>
            </a>
          </div>
        </div>

        <!-- Quick Button Reply -->
        <div class="rounded-md overflow-hidden mb-2" v-else-if="message.type.toUpperCase() == 'BUTTON'">
          <div class="text-gray-500 text-xs">Button Click</div>
        </div>
      </template>

      <!-- Instagram comment footer. -->
      <template v-if="message.content.instagramMessageType == 'comment'">
        <!-- Like/Share Button -->
        <div class="flex w-full px-3 py-1">
          <div class="flex-1 text-lg">
            <i class="fa-regular fa-heart text-gray-500"></i>
            <i class="ml-2 fa-sharp fa-regular fa-comment fa-flip-horizontal text-gray-500"></i>
            <i class="ml-3 fa-sharp fa-regular fa-paper-plane-top text-gray-500" style="transform: rotate(-40deg)"></i>
          </div>
          <div class="text-xs font-semibold">
            <a :href="post.permalink" target="_blank">
              <div class="py-2 instagram_color">VIEW ORIGINAL<i class="pl-1 fa-solid fa-arrow-up-right"></i></div>
            </a>
          </div>
        </div>

        <!-- Caption -->
        <div class="px-2 mb-3 text-sm text-gray-600">
          <span class="font-normal text-gray-8 font-semibold pr-1"> {{ channel.meta.instagramUsername }} </span>
          <span class="text-gray-500"> {{ getPostCaption() }}</span>
        </div>

        <!-- Comment Reply -->
        <div class="px-2 py-2 text-sm text-gray-700 border-t">
          <span class="font-normal text-gray-8 font-semibold pr-1"> {{ customer.name }} </span>
          {{ message.content.text }}
        </div>
      </template>

      <!-- normal footer -->
      <div class="p-2" v-else>
        <div class="whitespace-pre-line">{{ message.content.text }}</div>

        <!-- Footer: Text -->
        <div class="text-gray-400 mt-2" v-if="message.content.footerText">
          {{ message.content.footerText }}
        </div>

        <!-- Buttons -->
        <div class="mt-4" v-if="message.content.buttons && message.content.buttons.length > 0">
          <div class="p-2 bg-white mt-2 rounded-md" v-for="(button, buttonIndex) in message.content.buttons" :key="buttonIndex">
            <span class="text-blue-500"> {{ button.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'instagramMessageBlock',
  props: {
    customer: {
      type: Object,
      required: true
    },
    channel: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      post: false
    };
  },

  methods: {
    getPostCaption() {
      if (this.post?.caption) {
        if (this.post.caption.length > 55) {
          return this.post.caption.substring(0, 55) + '...';
        }
        return this.post.caption;
      } else {
        return 'No caption available';
      }
    },
    showMediaPreviewPopup(mediaUrl, mediaType) {
      this.$emit('showMediaReviewPopup', {
        mediaUrl,
        mediaType
      });
    }
  },
  mounted() {
    if (this.message.content.post) {
      this.post = JSON.parse(this.message.content.post);
    }
  }
};
</script>
