<template>
  <div class="mediaAssetPreviewPopup flex" @click="close" v-if="dialogVisible">
    <template v-if="mediaType == 'image'">
      <img ref="mediaAssetPreviewPopupImage" class="previewImage" :class="[isLandscapeMode ? 'landscapeMode' : 'portraitMode']" :src="mediaUrl" @load="onImageLoad" @click.stop="doNothing" />
    </template>
    <template v-if="mediaType == 'video'">
      <video @click.stop="doNothing" @load="onImageLoad" class="previewVideo" :class="[isLandscapeMode ? 'landscapeMode' : 'portraitMode']" controls>
        <source :src="mediaUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </template>
    <div class="h-full pl-2">
      <i class="fa fa-times text-white text-2xl top-3 right-3 cursor-pointer" @click="close"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mediaPreviewPopup',
  props: {},
  data() {
    return {
      mediaUrl: null,
      mediaType: null,
      dialogVisible: false,

      isLandscapeMode: false
    };
  },
  methods: {
    showPopup(mediaUrl, mediaType) {
      this.mediaUrl = mediaUrl;
      this.mediaType = mediaType;
      this.dialogVisible = true;
    },

    onImageLoad() {
      let imageRef = this.$refs.mediaAssetPreviewPopupImage;
      if (imageRef.width() > imageRef.height()) {
        // Landscape Mode.
        this.isLandscapeMode = true;
      } else {
        // Portrait Mode.
        this.isLandscapeMode = false;
      }
    },
    doNothing() {
      console.log('Do Nothing.');
    },
    close() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
.mediaAssetPreviewPopup {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4b4b4bcc;
  display: flex;
  justify-content: center;
  align-items: top;
  padding-top: 2vh;

  .previewImage {
    border-radius: 10px;
  }

  .previewImage.portraitMode {
    max-height: 95vh;
  }

  .previewImage.landscapeMode {
    max-width: 90%;
  }

  .previewVideo {
    border-radius: 10px;
    background-color: transparent !important;
  }

  .previewVideo.portraitMode {
    max-height: 95vh;
  }

  .previewVideo.landscapeMode {
    max-width: 90%;
  }

  // img {
  //   max-width: 80%;
  //   // max-height: 95vh;
  //   border-radius: 10px;
  // }

  // video {
  //   max-width: 80%;
  //   // max-height: 95vh;
  //   border-radius: 10px;
  // }
}
</style>
