const axios = require('axios');
const { chatServiceUrl, buildAuthHeaders } = require('./settings.js');

exports.getCompanySettingByKey = function (key) {
  return axios.get(chatServiceUrl + `/settings/bykey/${key}`, {
    headers: buildAuthHeaders()
  });
};

exports.saveCompanySettingByKey = function (params) {
  return axios.post(chatServiceUrl + `/settings/bykey`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getTagsList = function () {
  return exports.getCompanySettingByKey('chat-tags-list');
};

exports.saveTagsList = function (settings) {
  return exports.saveCompanySettingByKey({
    key: 'chat-tags-list',
    type: 'chat',
    subType: 'tags',
    settings: settings
  });
};
