<template>
  <div id="chatMainPage">
    <div class="mainContainer">
      <!-- Background color split screen for large screens -->
      <div class="relative flex min-h-screen flex-col border">
        <div class="w-full flex-grow flex">
          <div class="min-w-0 flex-1 bg-white xl:flex">
            <!-- Left Side -->
            <ConversationListComponent ref="conversationList" @onConversationRemoved="onConversationRemoved" @onConversationSelect="onConversationSelect"></ConversationListComponent>

            <!-- Middle Area -->
            <div class="bg-white lg:min-w-0 lg:flex-1">
              <ChatBoxComponent @onShowPastTicket="onShowPastTicket" ref="chatBox"></ChatBoxComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./chatMainPage.scss"></style>

<script>
import chatMainPageComponent from './chatMainPageComponent';
export default chatMainPageComponent;
</script>
