const { io } = require('socket.io-client');
const { chatServiceUrl } = require('./settings.js');

exports.init = function (roomName) {
  console.log('initing with room name', roomName);
  if (window.chatSocket == null) {
    // Init socket.
    window.chatSocket = io(chatServiceUrl, {
      query: {
        roomName: roomName
      }
    });
  }
};

exports.destroy = function () {
  if (window.chatSocket != null) {
    // Init socket.
    window.chatSocket.disconnect(true);
    window.chatSocket = null;
  }
};

exports.addListener = function (topic, callback) {
  if (window.chatSocket == null) {
    throw new Error('Socket not initialized yet.');
  }

  window.chatSocket.on(topic, callback);
};

exports.removeListener = function (topic) {
  if (window.chatSocket == null) {
    throw new Error('Socket not initialized yet.');
  }

  window.chatSocket.off(topic);
};
